const showPlayer = () => {
    const player = document.getElementById('sound-cloud-component-container');
    if (player) {
        player.style.display = '';
    }
};

const hidePlayer = () => {
    const player = document.getElementById('sound-cloud-component-container');
    if (player) {
        player.style.display = 'none';
    }
};

export {
    showPlayer,
    hidePlayer
}