import React from 'react';
import {showPlayer} from './utils/togglePlayer'

const ShowPlayer = () => {
    showPlayer();
    return (
        <></>
    )
};

export default ShowPlayer;