import React from 'react';
import {hidePlayer} from './utils/togglePlayer'

const Contact = () => {
    hidePlayer();
    return (
        <div id='contact-container'>
            <p id='contact-email'>FENDIFRADO@GMAIL.COM</p>
        </div>
    )
};

export default Contact;