import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Contact from './Contact'
import ShowPlayer from './ShowPlayer'
import fendiFradoLogo from './assets/fendiFradoLogo.png'
import backgroundAnimation from './assets/backgroundAnimation.svg'
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Router,
  Routes,
  Route,
  NavLink
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <div className='routes'>
        <img style={{position: 'fixed', zIndex: -1, height: '100%', width: '100%', minWidth: '1000px'}} src={backgroundAnimation} alt="Background stripes" />
      <nav className='nav'>
        <NavLink to="/"><img id='logo' src={fendiFradoLogo} /></NavLink>
        <nav className='right-nav'>
          {/* <NavLink to="/contact" className='nav-link' activeClassName='nav-link-selected'>Contact</NavLink> */}
          <NavLink to="/contact" className={(navData) => (navData.isActive ? "nav-link-selected" : 'nav-link')}>Contact</NavLink>
        </nav>
      </nav>
      <div id='separator'></div>
      <main>
        <App />
        <Routes>
          <Route path='/' element={<ShowPlayer />} />
          <Route path='contact' element={<Contact />} />
        </Routes>
      </main>
    </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
